import { mandatory } from 'validations';
import { getValidationFunction } from 'validation-helpers';
import { t } from 'bv-i18n';
import { memoize } from 'underscore';

const confirmation = ({ paramName, errorMessage }) => (value, allValues) => {
  if (value === allValues[paramName]) return undefined;
  return errorMessage;
};

export const oldPasswordValidations = mandatory(t('javascript.account.reset_password.required'));

export const currentPasswordValidations = memoize((allPwdValidations) => (
  getValidationFunction(allPwdValidations)
));

export const passwordConfirmationValidations = confirmation({
  paramName: 'password',
  errorMessage: t('javascript.account.reset_password.passwords_no_match'),
});
