import PropTypes from 'prop-types';
import ErrorItem from './error_item';

const ErrorList = ({ errorList }) => (
  <ul className="account-form__field-errors-list">
    { errorList.map((err) => <ErrorItem {...err} key={err.type} />) }
  </ul>
);

ErrorList.propTypes = {
  errorList: PropTypes.arrayOf(Object).isRequired,
};

export default ErrorList;
