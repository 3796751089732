import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, Card } from 'bv-components';
import { t } from 'bv-i18n';
import Password from 'SharedComponents/password/components/password';
import PasswordWithErrorList from 'SharedComponents/password_with_error_list';
import {
  oldPasswordValidations,
  passwordConfirmationValidations,
} from 'SharedComponents/password/helpers/validations';

const FormView = ({
  onSubmit, passwordRules,
}) => (
  <Card className="account-form__password-change">
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="old_password"
              formName="passwordChange"
              component={Password}
              validate={oldPasswordValidations}
              placeholder={t('javascript.account.reset_password.old_password')}
              withToggle
              showError={({ touched, error }) => touched && error}
            />
            <PasswordWithErrorList
              label={t('javascript.account.reset_password.new_password')}
              name="password"
              type="password"
              rules={passwordRules}
              isChangePassword
            />
            <Field
              name="password_confirmation"
              formName="passwordChange"
              component={Password}
              validate={passwordConfirmationValidations}
              placeholder={t('javascript.account.reset_password.retype_new_password')}
              withToggle
              showError={({ visited, dirty, error }) => visited && dirty && error}
            />
          </div>
          <div className="modal_sticky_button_wrapper bvs-button-sticky-wrapper">
            <Button
              primary
              label={t('javascript.account.reset_password.change_password')}
              disabled={invalid}
              className="bvs-button-sticky"
            />
          </div>
        </form>
      )}
    </Form>
  </Card>
);

FormView.propTypes = {
  passwordRules: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormView;
