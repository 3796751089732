import { values, isEmpty } from 'underscore';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

export const errorHandler = (error, onClick, onUnauthorizedRequest) => {
  if (error.status === 401) {
    onUnauthorizedRequest();
    return null;
  }

  return (
    <Modal
      danger
      title={isEmpty(error.errors) ? t('errors_something_wrong') : undefined}
      actions={[{
        label: t('close'), danger: true, onClick,
      }]}
    >
      {values(error.errors)
      && (
      <p>
        {values(error.errors).reduce((acc, errorMessage) => (
          [...acc, acc.concat(errorMessage)]
        ), [])}
      </p>
      )}
    </Modal>
  );
};

export const successHandler = (onClick) => (
  <Modal
    success
    actions={[{
      label: t('close'), primary: true, onClick,
    }]}
  >
    <p>{t('your_password_has_now_been_changed')}</p>
  </Modal>
);
