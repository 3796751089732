import { ModalWindow } from 'bv-components';
import { t } from 'bv-i18n';
import passwordChange from 'SharedComponents/password/ducks';
import App from './components/app';

const { addReducers } = window.reduxState;

addReducers({
  passwordChange,
});

const PasswordChange = () => (
  <ModalWindow className="password-change-modal" title={t('change_password')}>
    <App />
  </ModalWindow>
);

export default PasswordChange;
