import { getJSON, postJSON } from 'bv-fetch';

export const apiFetchPasswordRules = () => getJSON('/api/password_rules');

export const apiSubmitForm = (endpoint, params, csrfToken = null) => (
  postJSON(endpoint, { account: params }, csrfToken)
);

export const apiResetPassword = (endpoint, usernameOrEmail) => (
  postJSON(endpoint, { account: { username_or_email: usernameOrEmail } })
);
