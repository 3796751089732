import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { Spinner } from 'bv-components';
import { useHistory } from 'react-router-dom';

import {
  fetchPasswordRules as fetchPasswordRulesDucks,
  submitForm as submitFormDucks,
} from 'SharedComponents/password/ducks';
import { successHandler, errorHandler } from 'SharedComponents/password/helpers/handlers';
import Form from './form_view';

const App = ({
  fetchPasswordRules, canInitForm, formIsSubmitting, passwordRules,
  submitForm,
}) => {
  const [response, setResponse] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    fetchPasswordRules();
  }, []);

  if (!canInitForm || formIsSubmitting) return <Spinner />;

  const onSubmit = (params) => submitForm({
    endpoint: '/account/change_password',
    params,
    onSuccess: (success) => setResponse({ ...success }),
    onError: (error) => setResponse({ ...error, success: false }),
  });

  return (
    <>
      <Form
        passwordRules={passwordRules}
        onSubmit={onSubmit}
      />
      {response?.success && successHandler(() => { history.push('/bv_cashier/overview'); })}
      {response?.success === false
        && errorHandler(response, () => setResponse(undefined), () => { history.push('/login'); })}
    </>
  );
};

App.propTypes = {
  canInitForm: PropTypes.bool.isRequired,
  passwordRules: PropTypes.instanceOf(Object).isRequired,
  fetchPasswordRules: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  formIsSubmitting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  passwordRules: state.passwordChange.passwordRules,
  canInitForm: state.passwordChange.canInitForm,
  formIsSubmitting: state.passwordChange.formIsSubmitting,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPasswordRules: compose(dispatch, fetchPasswordRulesDucks),
  submitForm: (params) => compose(dispatch(submitFormDucks(params))),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
