import { useState } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v as bvVar } from 'bv';
import {
  password as passwordValidation,
  onlyAllowedSymbols as onlyAllowedSymbolsValidation,
} from 'validations';
import PasswordField from './password_field';

const Password = ({
  isChangePassword,
  rules,
  ...rest
}) => {
  const { allowedSymbols, maxSize: maxLength } = rules;

  const [validatorResults, setValidatorResults] = useState([]);
  const [allowedSymbolsError, setAllowedSymbolsError] = useState();
  const validationFunction = passwordValidation(rules);
  const onlyAllowedSymbols = allowedSymbols
    ? onlyAllowedSymbolsValidation(allowedSymbols)
    : () => undefined;

  const validator = (value) => {
    const validatedFields = validationFunction(value);
    setValidatorResults(validatedFields);

    if (validatedFields.some((validatedField) => !validatedField.valid)) {
      return validatedFields;
    }

    const allowedSymbolsResult = onlyAllowedSymbols(value);
    setAllowedSymbolsError(allowedSymbolsResult);
    return allowedSymbolsResult;
  };

  const pwdCard = bvVar('regPathCards').password;
  const useCard = pwdCard[bvVar('brandId')] !== undefined ? pwdCard[bvVar('brandId')] : pwdCard.default;
  const wrapperClass = classnames({
    'bvs-form-group': !useCard || isChangePassword,
    'bvs-card': useCard && !isChangePassword,
  });

  return (
    <div className={wrapperClass}>
      <Field
        validate={validator}
        validatorResults={validatorResults}
        allowedSymbolsError={allowedSymbolsError}
        component={PasswordField}
        maxLength={maxLength}
        type="password"
        {...rest}
      />
    </div>
  );
};

Password.propTypes = {
  isChangePassword: PropTypes.bool.isRequired,
  rules: PropTypes.instanceOf(Object).isRequired,
};

export default Password;
