import { compose } from 'underscore';
import { apiSubmitForm, apiFetchPasswordRules } from '../api';

const FETCH_PASSWORD_RULES_COMPLETE = 'passwordUpdate/FETCH_PASSWORD_RULES_COMPLETE';
const SUBMIT_FORM_INIT = 'passwordUpdate/UPDATE.SUBMIT_FORM_INIT';
const SUBMIT_FORM_COMPLETE = 'passwordUpdate/SUBMIT_FORM_COMPLETE';

export const fetchPasswordRulesComplete = (passwordRules) => ({
  type: FETCH_PASSWORD_RULES_COMPLETE,
  passwordRules,
});

export const submitFormInit = () => ({
  type: SUBMIT_FORM_INIT,
});

export const submitFormComplete = () => ({
  type: SUBMIT_FORM_COMPLETE,
});

export const submitForm = ({
  endpoint, params, onSuccess, onError, csrfToken = null,
}) => (dispatch) => {
  dispatch(submitFormInit());

  apiSubmitForm(endpoint, params, csrfToken)
    .then((data) => {
      dispatch(submitFormComplete());
      onSuccess(data);
    })
    .catch((error) => {
      dispatch(submitFormComplete());
      onError(error);
    });
};

export const fetchPasswordRules = () => (dispatch) => {
  apiFetchPasswordRules()
    .then(compose(dispatch, fetchPasswordRulesComplete));
};

const initialState = {
  passwordRules: null,
  canInitForm: false,
  formIsSubmitting: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PASSWORD_RULES_COMPLETE:
      return {
        ...state,
        passwordRules: action.passwordRules,
        canInitForm: true,
      };
    case SUBMIT_FORM_INIT:
      return {
        ...state,
        formIsSubmitting: true,
      };
    case SUBMIT_FORM_COMPLETE:
      return {
        ...state,
        formIsSubmitting: false,
      };
    default:
      return state;
  }
};
